import React from "react";

export interface ClickableCardProps {
  children: React.ReactNode;
  onClick: () => void;
}

const ClickableCard = (props: ClickableCardProps) => {
  return (
    <div
      onClick={() => props.onClick()}
      className="w-full h-inherit flex flex-col px-6 py-6 items-start justify-start rounded-lg bg-white shadow-cardShadow border-2 border-transparent hover:border-activeTeal cursor-pointer"
    >
      {props.children}
    </div>
  );
};

export default ClickableCard;
