import { useEffect, useState } from "react";
import { WPPost } from "../../types/wordpress";
import { getLatestChartOfTheDay } from "../../services/wordpressServices";
import HeaderText from "../text/headerText";
import HeaderSpacer from "../spacers/headerSpacer";
import { GridLoader } from "react-spinners";
import colors from "../../styles/colors";

const ChartOfTheDaySection = () => {
  const [chart, setChart] = useState<WPPost | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchChart = async () => {
      setIsLoading(true);
      const post = await getLatestChartOfTheDay();
      setChart(post);
      setIsLoading(false);
    };

    fetchChart();
  }, []);

  if (!chart && !isLoading) return null;

  return (
    <div className="w-inherit h-inherit flex flex-col items-start">
      <HeaderText text="Chart of the Day" />
      <HeaderSpacer />
      {isLoading ? (
        <div className="h-inherit md:lg:w-96 md:lg:p-20 flex justify-center items-center">
          <GridLoader
            color={colors.activeTeal}
            loading
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        chart && (
          <a
            href={chart.link}
            target="_blank"
            rel="noopener noreferrer"
            className="block w-full"
          >
            <div className="max-w-3xl">
              {chart._embedded?.["wp:featuredmedia"]?.[0] && (
                <img
                  src={chart._embedded["wp:featuredmedia"][0].source_url}
                  alt={
                    chart._embedded["wp:featuredmedia"][0].alt_text ||
                    "Chart of the day"
                  }
                  className="w-full h-auto rounded-lg shadow-lg"
                />
              )}
            </div>
          </a>
        )
      )}
    </div>
  );
};

export default ChartOfTheDaySection;
