import { useEffect, useState } from "react";
import { WPPost } from "../../types/wordpress";
import { getLatestDailyHeadlines } from "../../services/wordpressServices";
import HeaderText from "../text/headerText";
import HeaderSpacer from "../spacers/headerSpacer";
import Card from "../common/card";
import { GridLoader } from "react-spinners";
import colors from "../../styles/colors";

const DailyHeadlinesSection = () => {
  const [headlines, setHeadlines] = useState<WPPost[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchHeadlines = async () => {
      setIsLoading(true);
      const posts = await getLatestDailyHeadlines();
      setHeadlines(posts);
      setIsLoading(false);
    };

    fetchHeadlines();
  }, []);

  const decodeHtmlEntities = (text: string) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return textarea.value;
  };

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    return `${dateObj.toLocaleString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    })} at ${dateObj.toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      timeZone: "America/Chicago",
      timeZoneName: "short",
    })}`;
  };

  return (
    <div className="w-inherit h-inherit flex flex-col items-start">
      <HeaderText text="Industry News" />
      <HeaderSpacer />
      {isLoading ? (
        <div className="h-inherit md:lg:w-96 md:lg:p-20 flex justify-center items-center">
          <GridLoader
            color={colors.activeTeal}
            loading
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="w-full space-y-4">
          {headlines.map((post) => (
            <a
              key={post.id}
              href={post.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full hover:bg-gray-50 rounded-lg transition-colors duration-200"
            >
              <div className="w-full px-6 py-4 bg-white rounded-lg shadow-cardShadow">
                <div className="flex flex-col">
                  <h3 className="text-xl font-semibold text-gray-900 leading-tight">
                    {decodeHtmlEntities(post.title.rendered)}
                  </h3>
                  <span className="text-sm text-gray-500 mt-1">
                    {formatDate(post.date)}
                  </span>
                </div>
              </div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default DailyHeadlinesSection;
