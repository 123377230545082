import { ApplicationInfo } from "../../types/application";
import { IconNames, iconSelector } from "../../utils/icons";
import ClickableCard from "../common/clickableCard";
import StandardSpacer from "../spacers/standardSpacer";
import StandardDescriptionText from "../text/standardDescriptionText";
import StandardSemiboldText from "../text/standardSemiboldText";

const ApplicationLinkCard = (props: ApplicationInfo) => {
  const CaretRight = iconSelector(IconNames.CaretRight);

  //TODO: add extra code here if anything else is needed on handleCLick
  const handleClick = () => {
    console.log(props.initiate_login_uri);
  };

  return (
    <>
      {/* If there is no application link we don't want to render anything */}
      {props?.initiate_login_uri != null && (
        <a href={props.initiate_login_uri} target="_blank" className="w-full">
          <ClickableCard onClick={handleClick}>
            <div className="flex flex-row w-full h-inherit justify-between space-x-4">
              <div className="flex flex-col w-inherit h-inherit">
                <StandardSemiboldText text={props.name} />
                {props.description != null && (
                  <>
                    <StandardSpacer />
                    <StandardDescriptionText text={props.description} />
                  </>
                )}
              </div>
              <div className="flex flex-col flex-shrink-0 items-end justify-center">
                <CaretRight width={24} height={24} />
              </div>
            </div>
          </ClickableCard>
          <StandardSpacer />
        </a>
      )}
    </>
  );
};

export default ApplicationLinkCard;
