import { useAuth0 } from "@auth0/auth0-react";
import HeaderSpacer from "../components/spacers/headerSpacer";
import PageHeaderText from "../components/text/pageHeaderText";
import ApplicationsSection from "../components/WelcomePage/applicationsSection";
import SupportSection from "../components/WelcomePage/supportSection";
import DailyHeadlinesSection from "../components/WelcomePage/dailyHeadlinesSection";
import ChartOfTheDaySection from "../components/WelcomePage/chartOfTheDaySection";

const WelcomePage = () => {
  const { user } = useAuth0();

  return (
    <div className="w-full h-full flex flex-col px-8 py-6 items-start">
      {/* If there is a user name display that, otherwise display Welcome back! */}
      <PageHeaderText
        text={`Welcome back${
          user != null && user?.name != null ? `, ${user.name}` : "!"
        }`}
      />
      <HeaderSpacer />
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 lg:px-8">
        <ApplicationsSection />
        <SupportSection />
      </div>
      <HeaderSpacer />
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 lg:px-8">
        <DailyHeadlinesSection />
        <ChartOfTheDaySection />
      </div>
      <HeaderSpacer />
    </div>
  );
};

export default WelcomePage;
