import { WPPost } from "../types/wordpress";

const WP_API_BASE_URL = "https://insights.ever.ag/wp-json/wp/v2";

// Cache for category IDs to avoid multiple requests
let categoryCache: { [key: string]: number } = {};

const getCategoryId = async (categorySlug: string): Promise<number | null> => {
  if (categoryCache[categorySlug]) {
    return categoryCache[categorySlug];
  }

  try {
    const response = await fetch(
      `${WP_API_BASE_URL}/categories?slug=${categorySlug}`
    );
    if (response.ok) {
      const categories = await response.json();
      if (categories && categories.length > 0) {
        categoryCache[categorySlug] = categories[0].id;
        return categories[0].id;
      }
    }
    return null;
  } catch (error) {
    console.error(`Error fetching category ID for ${categorySlug}:`, error);
    return null;
  }
};

export const getLatestDailyHeadlines = async (): Promise<WPPost[]> => {
  try {
    const categoryId = await getCategoryId("daily-headlines");
    if (!categoryId) {
      console.error("Could not find daily-headlines category");
      return [];
    }

    const response = await fetch(
      `${WP_API_BASE_URL}/posts?categories=${categoryId}&per_page=4&_embed`
    );
    if (response.ok) {
      const posts = await response.json();
      return posts;
    }
    return [];
  } catch (error) {
    console.error("Error fetching daily headlines:", error);
    return [];
  }
};

export const getLatestChartOfTheDay = async (): Promise<WPPost | null> => {
  try {
    const categoryId = await getCategoryId("chart-of-the-day");
    if (!categoryId) {
      console.error("Could not find chart-of-the-day category");
      return null;
    }

    const response = await fetch(
      `${WP_API_BASE_URL}/posts?categories=${categoryId}&per_page=1&_embed`
    );
    if (response.ok) {
      const posts = await response.json();
      return posts[0] || null;
    }
    return null;
  } catch (error) {
    console.error("Error fetching chart of the day:", error);
    return null;
  }
};
